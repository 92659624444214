<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <SupplierDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></SupplierDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('supplier.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
          <SupplierFilter 
          v-on:onFilterChange="onFilterChange"
          ></SupplierFilter>
        </template>
        <template v-slot:item.name="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="disableItem(item,$t('supplier.supplier'),item.name_tc)"
                >mdi-block-helper</v-icon
              >
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import SupplierDialog from "../dialogs/SupplierDialog"
import BaseTable from "./BaseTable.vue";
import SupplierFilter from "../filters/SupplierFilter"
import { mapState } from "vuex";
export default {
  data: vm => ({
    url: "/supplier/supplier/",
    headersMap: {
      id: {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "10%"
      },
      name_tc: {
        sortable: true,
        text: vm.$i18n.t("supplier.name_tc"),
        value: "name_tc",
        width: "10%"
      },
      name_sc: {
        sortable: true,
        text: vm.$i18n.t("supplier.name_sc"),
        value: "name_sc",
        width: "10%"
      },
      actions: {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%"
      }
    },
    tcHeaders: ['id', 'name_tc', 'actions'],
    scHeaders: ['id', 'name_sc', 'actions'],
    adminHeaders: ['id', 'name_tc', 'name_sc', 'actions'],
    items: [],
    options: {},
    totalItems: 0,
    loading: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100]
    },
  }),
  mixins: [BaseTable],
  computed: {
    ...mapState(["role","language"]),
    showHeaders () {
      var headers = []
      var headerKeys = this.tcHeaders
      if (this.language==='zhHans') {
        headerKeys = this.scHeaders
      }
      if (this.role===0) {
        headerKeys = this.adminHeaders
      }
      headerKeys.forEach(key => {
        headers.push(this.headersMap[key])
      })
      return headers
    },
  },
  components: {
    SupplierDialog,
    SupplierFilter,
    CommonAlertDialog
  },
  methods: {
    get_supplier_name(item){
      return this.getHanText(item,"name")
    },
  },
  mounted() {
    this.getParams['enable'] = true
  }
}
</script>
